
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "InvoiceGhipStatusLabel",
  props: {
    status: {required: true}
  },
  setup(props) {
    const data = ref({clazz: '', name: ''})
    const buildStatus = (status) => {
      switch (status) {
        case 'ELIGIBLE':
          data.value = {clazz: 'dark', name: 'Eligible'}
          break
        case 'IN_PROGRESS':
          data.value = {clazz: 'primary', name: 'In-Progress'}
          break
        case 'SUBMITTED':
          data.value = {clazz: 'info', name: 'Submitted'}
          break
        case 'REJECTED':
          data.value = {clazz: 'danger', name: 'Rejected'}
          break
        case 'COMPLETED':
          data.value = {clazz: 'success', name: 'Completed'}
          break
        case 'INELIGIBLE':
          data.value = {clazz: 'danger', name: 'Ineligible'}
          break
      }
    }
    watch(() => props.status, (cb) => {
      buildStatus(cb)
    })
    buildStatus(props.status)
    return {
      data
    }
  }
})
