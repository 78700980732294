
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {Field} from "vee-validate";
import Froala from "@/components/base/editor/Froala.vue";
import InvoiceService from "@/core/services/InvoiceService";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";

export default defineComponent({
  name: "InvoiceGhipStatusForm",
  components: {DateTimeSelect, Froala, BaseRadio, BaseForm, Field},
  props: {
    invoiceId: {type: String, required: true},
    data: {type: Object},
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);

    const model = ref(props.data);

    watch(() => props.data, cb => {
      model.value = cb;
    })
    return {
      model,
      submitting,
    }
  },
  methods: {
    submit: function () {
      this.submitting = true;
      InvoiceService.updateGhipStatus(this.invoiceId, this.model).then(res => {
        this.$emit('saved', res)
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
