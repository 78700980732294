
import { computed, defineComponent } from "vue";
import store from "@/store";
import RowCol from "@/components/base/common/RowCol.vue";
import { LoadPanel } from "@/core/composite/composite";

export default defineComponent({
  name: "ProviderView",
  components: {RowCol},
  setup() {
    const invoice = computed(() => store.state.InvoiceModule.invoice);
    return {
      invoice,
      ...LoadPanel(),
    }
  }
})
