
import {computed, defineComponent} from "vue";
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import LinesHCFA from "@/views/invoice/LinesHCFA.vue";
import LinesUB04 from "@/views/invoice/LinesUB04.vue";
import Timelines from "@/views/timeline/Timelines.vue";
import {LoadPanel} from "@/core/composite/composite";
import UB04 from "@/views/invoice/UB04.vue";
import {ModelInvoiceUB} from "@/core/entity/IInvoiceUB04";
import HCFA from "@/views/invoice/HCFA.vue";
import LinesTripCan from "@/views/invoice/LinesTripCan.vue";
import LinesLostBag from "@/views/invoice/LinesLostBag.vue";
import RepriceView from "@/views/invoice/RepriceView.vue";
import AdjudicationView from "@/views/invoice/AdjudicationView.vue";
import ProviderView from "@/views/invoice/ProviderView.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import DecodeId from "@/components/base/DecodeId.vue";
import LineGeneric from "@/views/invoice/LineGeneric.vue";
import RowCol from "@/components/base/common/RowCol.vue";
import LineDental from "@/views/invoice/LineDental.vue";
import ProductLink from "@/views/product/ProductLink.vue";
import LineRX from "@/views/invoice/LineRX.vue";
import InvoiceGhipOverview from "@/views/invoice/ghip/InvoiceGhipOverview.vue";

export default defineComponent({
  name: "Overview",
  components: {
    InvoiceGhipOverview,
    LineRX,
    ProductLink,
    LineDental,
    RowCol,
    LineGeneric,
    DecodeId,
    DateTimeFormat,
    ProviderView,
    AdjudicationView,
    RepriceView, LinesLostBag, LinesTripCan, HCFA, UB04, Timelines, LinesUB04, LinesHCFA, Row
  },
  setup() {
    const invoice = computed(() => store.state.InvoiceModule.invoice)
    return {
      invoice,
      ...LoadPanel(),
      ModelInvoiceUB,
    }
  }
})
