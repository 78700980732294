import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowCol = _resolveComponent("RowCol")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RowCol, {
      label: "Billing Provider",
      value: _ctx.invoice.provider,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createElementVNode("span", {
          class: "link-primary cursor-pointer hover",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadData(_ctx.invoice?.provider?.id,'PROVIDER')))
        }, _toDisplayString(_ctx.invoice?.provider.name), 1)
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: _ctx.invoice.type.code  === 'UB04' ? `Service Provider`: `Service Facility`,
      value: _ctx.invoice.payTo,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createElementVNode("span", {
          class: "link-primary cursor-pointer hover",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadData(_ctx.invoice?.payTo?.id,'PROVIDER')))
        }, _toDisplayString(_ctx.invoice?.payTo.name), 1)
      ]),
      _: 1
    }, 8, ["label", "value"]),
    _createVNode(_component_RowCol, {
      label: _ctx.invoice.type.code === 'UB04' ? `Operating Provider`: `Doctor`,
      value: _ctx.invoice.doctor,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createElementVNode("span", {
          class: "link-primary cursor-pointer hover",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadData(_ctx.invoice?.doctor?.id,'PROVIDER')))
        }, _toDisplayString(_ctx.invoice?.doctor.name), 1)
      ]),
      _: 1
    }, 8, ["label", "value"]),
    _createVNode(_component_RowCol, {
      label: _ctx.invoice.type.code === 'UB04' ? `Attending Provider`: `Referring orovider`,
      value: _ctx.invoice.referring,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createElementVNode("span", {
          class: "link-primary cursor-pointer hover",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadData(_ctx.invoice?.referring?.id,'PROVIDER')))
        }, _toDisplayString(_ctx.invoice?.referring.name), 1)
      ]),
      _: 1
    }, 8, ["label", "value"])
  ]))
}