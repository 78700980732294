
import { defineComponent } from "vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";

export default defineComponent({
  name: "LinesLostBag",
  components: {CurrencyFormat, DateTimeFormat},
  props: {
    lines: {type: Array},
    currency: {type: String}
  }
})
