
import {computed, defineComponent} from 'vue'
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Row from "@/components/base/common/Row.vue";
import InvoiceGhipStatusLabel from "@/views/invoice/ghip/InvoiceGhipStatusLabel.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import InvoiceGhipStatusForm from "@/views/invoice/ghip/InvoiceGhipStatusForm.vue";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "InvoiceGhipOverview",
  components: {
    DateTimeFormat,
    InvoiceGhipStatusForm, BaseModal, CurrencyFormat, InvoiceGhipStatusLabel, Row, QuickAction
  },
  setup() {
    const invoice = computed(() => store.state.InvoiceModule.invoice);
    return {
      invoice,
    }
  },
  methods: {
    update() {
      const modal = this.$refs.updateGhipStatusRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      const modal = this.$refs.updateGhipStatusRef as typeof BaseModal;
      modal.hideBaseModal();
      store.dispatch(Actions.LOAD_INVOICE, this.invoice.id)
      store.commit(Mutations.ADDED_TAGS)
    }
  }
})
