<template>
  <table class="table ">
    <thead>
    <tr>
      <th style="font-size: 11px; font-weight: bold">RX#</th>
      <th style="font-size: 11px; font-weight: bold">Desc</th>
      <th style="font-size: 11px; font-weight: bold" align="right">Amount</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(line , idx) in lines" v-bind:key="idx">
      <td>
        {{line.pos}}
      </td>
      <td>
        {{line?.desc}}
      </td>
      <td>
        {{line.amount}}
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "LineRX",
  props: {
    lines: {type: Array},
    currency: {type: String},
  }
}
</script>

<style scoped>

</style>