
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "AdjudicateTypeLabel",
  props: {
    type: {type: String, required:true}
  },
  setup(props) {
    const label = ref('');
    const buildLabel = (code: string) => {
      switch (code) {
        case 'INELIGIBLE':
          label.value = 'Ineligible';
          break;
        case 'DEDUCTIBLE':
          label.value = 'Deductible';
          break;

        case 'COINSURANCE':
          label.value = 'Coinsurance';
          break;
        case 'COPAY':
          label.value = 'Copay';
          break;
        case 'OTHER_INSURANCE':
          label.value = 'Other Insurance';
          break;
        case 'CLIENT_RESPONSIBILITY':
          label.value = 'Client Responsibility';
          break;
        case 'CLIENT_PAID':
          label.value = 'Client Paid';
          break;
      }
    }
    buildLabel(props.type);
    watch(() => props.type, (cb) => {
      buildLabel(cb);
    })
    return {
      label
    }
  }
})
