
import {defineComponent} from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Row from "@/components/base/common/Row.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import {LoadPanel} from "@/core/composite/composite";
import Swal from "sweetalert2";
import InvoiceService from "@/core/services/InvoiceService";
import {makeDownloadLink} from "@/core/helpers/functions";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "RepriceView",
  components: {CurrencyFormat, Row, QuickAction},
  props: {
    invoice: {type: Object}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  },
  methods: {
    onDownloadRepriced(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.exportRepriced(id).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
    nonReprice(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, set to Not-Repriceable',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          Swal.fire({
            heightAuto: false,
            onOpen: () => {
              Swal.showLoading()
            },
          });
          InvoiceService.nonReprice(id).then(() => {
            store.dispatch(Actions.LOAD_INVOICE, id)
          }).catch(error => {
            console.log(error)
          }).finally(() => {
            Swal.close();
          })
        }
      })
    }
  }
})
