<template>
  <table class="table ">
  <thead>
  <tr>
    <th style="font-size: 11px; font-weight: bold">DOS From</th>
    <th style="font-size: 11px; font-weight: bold">To</th>
    <th style="font-size: 11px; font-weight: bold">Desc</th>
    <th style="font-size: 11px; font-weight: bold" align="right">Amount</th>

  </tr>
  </thead>
  <tbody>
  <tr v-for="(line , idx) in lines" v-bind:key="idx">
    <td>
      <DateTimeFormat :date="line.fromDt" />
    </td>
    <td>
      <DateTimeFormat :date="line.toDt" />
    </td>
    <td>
      {{line?.desc}}
    </td>
    <td align="right">
      <CurrencyFormat :amount="line.amount" :currency="currency" />
    </td>
    <td>
      {{line.units}}
    </td>
  </tr>
  </tbody>
  </table>
</template>

<script>
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat";
export default {
  name: "LineGeneric",
  components: {CurrencyFormat, DateTimeFormat},
  props: {
    lines: {type: Array},
    currency: {type: String},
  }
}
</script>

<style scoped>

</style>