import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowCol = _resolveComponent("RowCol")!
  const _component_Lookup = _resolveComponent("Lookup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RowCol, {
      label: "Medicare",
      value: _ctx.data.isMedicare,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.data.isMedicare ? 'YES': 'NO'), 1)
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Tricare",
      value: _ctx.data.isTriCare,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.data.isTriCare ? 'YES': 'NO'), 1)
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Champva",
      value: _ctx.data.isChamPva,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.data.isChamPva ? 'YES': 'NO'), 1)
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Group Plan",
      value: _ctx.data.isGroupHealthPlan,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.data.isGroupHealthPlan ? 'YES': 'NO'), 1)
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Diagnosis",
      value: "",
      "show-if-null": true,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createVNode(_component_Lookup, {
          type: "ICD10CM",
          code: _ctx.data.diag1
        }, null, 8, ["code"]),
        _createVNode(_component_Lookup, {
          type: "ICD10CM",
          code: _ctx.data.diag2
        }, null, 8, ["code"]),
        _createVNode(_component_Lookup, {
          type: "ICD10CM",
          code: _ctx.data.diag3
        }, null, 8, ["code"]),
        _createVNode(_component_Lookup, {
          type: "ICD10CM",
          code: _ctx.data.diag4
        }, null, 8, ["code"])
      ]),
      _: 1
    })
  ]))
}