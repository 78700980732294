
import { defineComponent } from "vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";


export default defineComponent({
  name: "LinesTripCan",
  components: { CurrencyFormat},
  props: {
    lines: {type: Array},
    currency: {type: String}
  }
})
