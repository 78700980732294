
import {computed, defineComponent} from "vue";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Row from "@/components/base/common/Row.vue";
import AdjudicateTypeLabel from "@/views/invoice/AdjudicateTypeLabel.vue";
import AdjudicationStatusLabel from "@/views/invoice/AdjudicationStatusLabel.vue";
import Swal from "sweetalert2";
import InvoiceService from "@/core/services/InvoiceService";
import {makeDownloadLink} from "@/core/helpers/functions";

export default defineComponent({
  name: "AdjudicationView",
  components: {AdjudicationStatusLabel, AdjudicateTypeLabel, Row, CurrencyFormat, QuickAction},
  setup() {
    const invoice = computed(() => store.state.InvoiceModule.invoice);
    return {
      invoice
    }
  },
  methods: {
    onDownloadEob(id, cheque: boolean) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      InvoiceService.exportEOB(id, cheque).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    },
  }
})
