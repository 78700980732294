
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "AdjudicationStatusLabel",
  props: {
    status: {type: String, required: true}
  },
  setup(props) {
    const label = ref('');
    const buildLabel = (code) => {
      if (code == null) {
        label.value = "To Adjudicate";
        return;
      }
      switch (code) {
        case 'TO_ADJUDICATE':
          label.value = 'To Adjudicate'
          break
        case 'PENDING':
          label.value = 'Pending';
          break
        case 'NONE_PAYABLE':
          label.value = 'Denied';
          break
        case 'INELIGIBLE':
          label.value = 'Ineligible'
          break;
        case 'NO_CLAIM':
          label.value = 'No Claim'
          break
        case 'ADJUDICATED':
          label.value = 'Payable'
          break
        case 'PAID':
          label.value = 'Paid'
          break
      }

    }
    buildLabel(props.status)
    watch(() => props.status, (cb) => {
      buildLabel(cb);
    })
    return {
      label,
    }
  }
})
