import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowCol = _resolveComponent("RowCol")!
  const _component_Lookup = _resolveComponent("Lookup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RowCol, {
      label: "Patient Ctr#",
      value: _ctx.data.patientAccNumber,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Medical Rec#",
      value: _ctx.data.medicalRecNumber,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Type of Bill",
      value: _ctx.data.typeOfBill,
      "show-if-null": false
    }, null, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Principal Dx",
      value: _ctx.data.diag1,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createVNode(_component_Lookup, {
          code: _ctx.data.diag1,
          type: "ICD10CM"
        }, null, 8, ["code"])
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Admit Dx",
      value: _ctx.data.admitDxCode,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createVNode(_component_Lookup, {
          code: _ctx.data.admitDxCode,
          type: "ICD10CM"
        }, null, 8, ["code"])
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_RowCol, {
      label: "Reason Code",
      value: _ctx.data.reasonDx,
      "show-if-null": false,
      type: "CUSTOM"
    }, {
      custom: _withCtx(() => [
        _createVNode(_component_Lookup, {
          code: _ctx.data.reasonDx,
          type: "ICD10CM"
        }, null, 8, ["code"])
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}